<template>
  <div id="inventoryRecord">
    <search
      :addName="addName"
      @searchBut="searchBut"
      :seceltModel="seceltModel"
      @searchFormType="searchFormType"
      :searchSelectList="specifications"
      :searchListInfo="searchListInfo"
    />
    <div class="stockBox">
      <div class="stockItem" v-for="item in stockList" :key="item.id" >
        <img
          :src="
            item.inputsImg && item.inputsImg.length != 0
              ? item.inputsImg[0].url
              : ''
          "
          alt=""
        />
        <div class="stockInfo">
          <span>名称：{{ item.inputName }}</span>
          <span>库存：{{ item.quantity }}</span>
          <span>规格：{{ item.unit }}</span>
          <!-- <span>备注：{{ item.remarks }}</span> -->
        </div>
      </div>
    </div>
    <page
      @changePage="eventPage"
      @changeSize="eventPage"
      class="pageStyle"
      :pagination="pagination"
    />
  </div>
</template>

<script>
import search from "@/components/soureUnit/tableSearch.vue";
import page from "@/components/page.vue";
export default {
  components: {
    search,
    page,
  },
  data() {
    return {
      addName: "addName",
      searchListInfo: {
        name: "投入品名称",
        type: "规格",
      },
      seceltModel: true,
      stockList: [],
      pagination: {
        totalRow: 0,
        page: 1,
        size: 10,
      },
      specifications: [],
    };
  },
  mounted() {
    //console.log("mounted");
    //console.log(this.$attrs);
    this.requestStock();
  },
  methods: {
    eventPage() {
      this.requestStock();
    },
    searchBut(val) {
      this.pagination.page = 1;
      this.pagination.size = 8;
      this.requestStock(val.name);
    },
    requestStock(name, specifications) {
      var _this=this;
      this.$get("/inputPurchase/pageRepertory", {
        page: this.pagination.page,
        size: this.pagination.size,
        inputName: name || "",
        unit: specifications || "",
      }).then((res) => {
        if (res.data.state == "success") {
          let { data } = res.data;
          data.forEach((element,index) => {
            element.inputsImg=element.inputsImg?JSON.parse(element.inputsImg):[];
            if(element.inputsImg.length>0){
              element.inputsImg = _this.showImg(element.inputsImg);
            }

          });
          this.stockList = data;
          this.pagination.totalRow = res.data.size;
        }else{
          //console.log("获取失败",res)
        }
      });
    },
    //显示图片
    showImg(baseImg){
      //console.log("baseImg:",baseImg)
      let imgList=[];
      for (let a = 0; a < baseImg.length; a++) {
        this.$getBlob("/fileops/show", {
          fileName: baseImg[a],
        }).then((res) => {
          let fileNames = res.config.params.fileName;
          let fileData = fileNames.split(".")[1];
          let blob = new Blob([res.data],{type: 'image/'+ fileData})
          let url = window.URL.createObjectURL(blob);
          imgList.push({name: baseImg[a], url: url});
          //this.baseFileName.push(baseImg[a]);

        })

      }
      return imgList
    },

    searchFormType(val) {
      this.requestStock("", val);
    },
    isJSON_test(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          //console.log(obj);
          return true;
        } catch (e) {
          //console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
    },
  },
};
</script>
<style scoped lang="less">
#inventoryRecord {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
  height: 90vh;
}

.stockBox {
  display: flex;
  flex-wrap: wrap;
  margin: 2vh 0;
  max-height: 72vh;
}

.stockItem {
  width: 15vw;
  height: 17vh;
  border: 1px solid #ccc;
  display: flex;
  margin-top: 1vh;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 2vh;
  img {
    width: 8vh;
    height: 8vh;
  }
  .stockInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.5vh;
    span {
      width: 9vw;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 4vh;
    }
  }
}

#inventoryRecord /deep/ .storkBut {
  display: none;
}

// .pageStyle {
//   position: absolute;
//   bottom: 2vh;
// }
</style>
